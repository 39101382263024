export default (date) => {
  if (!date) {
    date = useDayjs().format();
  }

  return Intl.supportedValuesOf('timeZone').map((name) => {
    const offsetInMinutes = useDayjs(date).tz(name).utcOffset();
    const duration = useDayjs.duration(offsetInMinutes, 'minutes').format('HH:mm').replaceAll('-', '').padStart(5, '0');
    const offsetFormatted = `${offsetInMinutes >= 0 ? '+' : '-'}${duration}`;
    const gmtPrefix = isNaN(offsetInMinutes) ? '' : `(GMT${offsetFormatted}) `;

    return {
      id: name,
      text: `${gmtPrefix}${name?.replace(/_/g, ' ')}`,
      offset: offsetInMinutes,
    };
  }).sort((a, b) => {
    if (a.offset === b.offset) {
      return a.id.localeCompare(b.id);
    }

    return a.offset - b.offset;
  });
};
